react-tagsinput {
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
}

.react-tagsinput--focused {
    @apply border-2 border-blue-500;
}

.react-autosuggest__container {
    @apply inline-block;
}

.react-tagsinput-tag {
    @apply font-semibold;
    @apply border;
    @apply text-sm;
    @apply rounded-lg;
    @apply inline-block;
    @apply w-auto;
    @apply pl-10;
    @apply p-2.5;
    @apply bg-secondary-orange2;
    @apply placeholder-secondary-grey3;
    @apply text-white;
    @apply focus:ring-blue-500;
    @apply focus:border-blue-500;
    @apply mt-1;
    @apply mr-1;
    @apply p-1;
}

.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a::before {
    content: " ×";
}

.react-tagsinput-input {
    @apply text-white;
    @apply placeholder-white;
    @apply font-semibold;
    @apply text-sm;
    @apply flex;
    @apply flex-wrap;
    background: transparent;
    border: 0;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 80px;
}

.creator-table-body {
    height: 70vh;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}